import React, { useState } from "react";
import { CreateContact } from "../../Apiservice";
import { Link } from "react-router-dom";
import { Footer } from "../navbar/Footer";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await CreateContact(formData);
      console.log("Form submitted successfully:", response);
      setSuccess(true);
      // Clear the form
      setFormData({
        name: "",
        email: "",
        mobile: "",
        address: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      setError("There was an error submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        className="page-title dark-background"
        style={{ backgroundImage: "url(assets/img/Bannerimages/Contact.jpg)" }}
      >
        <div className="container position-relative">
          <h1 className="works-banner">Contact</h1>
          <nav className="breadcrumbs">
            {/* <ol>
              <li><Link to="/">Home</Link></li>
              <li className="current">Contact</li>
            </ol> */}
          </nav>
        </div>
      </div>

      <section id="contact" className="contact section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="info-item d-flex flex-column justify-content-center align-items-center">
                <i className="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <p>MIG - 462 , Old ASTC Hudco , Hosur - 635 109 .</p>
                <p>27/G5 ,Kuttiyan St lane , Kumbakonam - 612 001 .</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="info-item d-flex flex-column justify-content-center align-items-center">
                <i className="bi bi-telephone"></i>
                <h3>Call Us</h3>
                <p>
                  <Link to="tel:+919488972821">+91 94889 72821</Link> /{" "}
                  <Link to="tel:+918124850138">+91 81248 50138</Link>
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="info-item d-flex flex-column justify-content-center align-items-center">
                <i className="bi bi-envelope"></i>
                <h3>Email Us</h3>
                <p>
                  <Link
                    to="mailto:vogelkop.architects@gmail.com"
                    target="blank"
                  >
                    vogelkop.architects@gmail.com
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="row gy-4 mt-1">
            <div className="col-lg-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15566.644520326765!2d77.8193351!3d12.735516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae7110a65f0505%3A0x235b21d049e35c2!2sVogelkop%20Architects!5e0!3m2!1sen!2sin!4v1720089674719!5m2!1sen!2sin"
                frameborder="0"
                style={{ width: "100%", height: "400px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Vogelkop Architects Location Map"
              ></iframe>
            </div>

            <div className="col-lg-6">
              <form onSubmit={handleSubmit} className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    name="mobile"
                    className="form-control"
                    placeholder="Contact Number"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Your Address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    name="message"
                    className="form-control"
                    rows="5"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                {loading && <div className="loading">Loading...</div>}
                {error && <div className="error-message">{error}</div>}
                {success && (
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                )}
                <div className="text-center mt-3">
                  <button type="submit" disabled={loading}>
                    {loading ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;

// import React, { useState } from "react";
// import { CreateContact } from "../../Apiservice";
// import { Footer } from "../navbar/Footer";

// const Contact = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     address: "",
//     subject: "",
//     message: "",
//   });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [success, setSuccess] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);
//     setSuccess(false);

//     try {
//       const response = await CreateContact(formData);
//       console.log("Form submitted successfully:", response);
//       setSuccess(true);
//       // Clear the form
//       setFormData({
//         name: "",
//         email: "",
//         mobile: "",
//         address: "",
//         subject: "",
//         message: "",
//       });
//     } catch (error) {
//       console.error("There was an error submitting the form:", error);
//       setError("There was an error submitting the form. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <div
//         className="page-title dark-background"
//         style={{ backgroundImage: "url(assets/img/Bannerimages/Contact.jpg)" }}
//       >
//         <div className="container position-relative">
//           <h1 className="works-banner">Contact</h1>
//         </div>
//       </div>

//       <section id="contact" className="contact section">
//         <div className="container">
//           <div className="row gy-4">
//             {/* Address Section */}
//             <div className="col-lg-6">
//               <div className="info-item">
//                 <h3>Address</h3>
//                 <p>MIG - 462 , Old ASTC Hudco, Hosur - 635 109.</p>
//                 <p>27/G5, Kuttiyan St lane, Kumbakonam - 612 001.</p>
//               </div>
//             </div>

//             <div className="col-lg-6">
//               <form onSubmit={handleSubmit} className="php-email-form">
//                 <div className="row">
//                   <div className="col-md-6 form-group">
//                     <input
//                       type="text"
//                       name="name"
//                       className="form-control"
//                       placeholder="Your Name"
//                       value={formData.name}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-md-6 form-group">
//                     <input
//                       type="email"
//                       name="email"
//                       className="form-control"
//                       placeholder="Your Email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="form-group mt-3">
//                   <input
//                     type="text"
//                     name="mobile"
//                     className="form-control"
//                     placeholder="Contact Number"
//                     value={formData.mobile}
//                     onChange={handleChange}
//                     required
//                   />
//                 </div>
//                 <div className="form-group mt-3">
//                   <input
//                     type="text"
//                     name="address"
//                     className="form-control"
//                     placeholder="Your Address"
//                     value={formData.address}
//                     onChange={handleChange}
//                     required
//                   />
//                 </div>
//                 <div className="form-group mt-3">
//                   <textarea
//                     name="message"
//                     className="form-control"
//                     rows="5"
//                     placeholder="Message"
//                     value={formData.message}
//                     onChange={handleChange}
//                     required
//                   ></textarea>
//                 </div>
//                 {loading && <div className="loading">Loading...</div>}
//                 {error && <div className="error-message">{error}</div>}
//                 {success && (
//                   <div className="sent-message">
//                     Your message has been sent. Thank you!
//                   </div>
//                 )}
//                 <div className="text-center mt-3">
//                   <button type="submit" disabled={loading}>
//                     {loading ? "Sending..." : "Send Message"}
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Footer />
//     </div>
//   );
// };

// export default Contact;
