import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

const Works = () => {
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingProjects, setLoadingProjects] = useState(true);

  // Fetch categories
  useEffect(() => {
    fetch("https://api.vogelkoparchitects.com/api/api/categories/")
      .then((response) => response.json())
      .then((data) => {
        console.log("Categories:", data);
        setCategories(data);
        setLoadingCategories(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setLoadingCategories(false);
      });
  }, []);

  // Fetch all projects initially
  useEffect(() => {
    fetch("https://api.vogelkoparchitects.com/api/api/projects/")
      .then((response) => response.json())
      .then((data) => {
        console.log("All Projects:", data);
        setProjects(data);
        setLoadingProjects(false);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
        setLoadingProjects(false);
      });
  }, []);

  useEffect(() => {
    if (selectedCategoryId !== null) {
      setLoadingProjects(true);
      fetch(
        `https://api.vogelkoparchitects.com/api/api/projects/${selectedCategoryId}/`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Filtered Projects:", data);
          setProjects(data);
          setLoadingProjects(false);
        })
        .catch((error) => {
          console.error("Error fetching projects:", error);
          setLoadingProjects(false);
        });
    }
  }, [selectedCategoryId]);

  // Handle category change
  const handleCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
    if (categoryId === null) {
      // Fetch all projects when "All" is selected
      setLoadingProjects(true);
      fetch("https://api.vogelkoparchitects.com/api/api/projects/")
        .then((response) => response.json())
        .then((data) => {
          setProjects(data);
          setLoadingProjects(false);
        })
        .catch((error) => {
          console.error("Error fetching all projects:", error);
          setLoadingProjects(false);
        });
    }
  };

  return (
    <div>
      <div
        className="page-title dark-background"
        style={{ backgroundImage: "url(assets/img/Bannerimages/WORKS.jpg)" }}
      >
        <div className="container position-relative">
          <h1 className="works-banner">Works</h1>
        </div>
      </div>

      <div className="workpage-main mt-5 mb-5">
        {/* Category Buttons */}
        <div className="workbutton-group filters-button-group">
          {loadingCategories ? (
            <p>Loading categories...</p>
          ) : (
            <>
              {/* "All" Button */}
              <button
                className={`workbutton ${
                  selectedCategoryId === null ? "is-checked" : ""
                }`}
                onClick={() => handleCategoryChange(null)}
              >
                All
              </button>
              {/* Category Buttons */}
              {categories.map((category) => (
                <button
                  key={category.id}
                  className={`workbutton ${
                    selectedCategoryId === category.id ? "is-checked" : ""
                  }`}
                  onClick={() => handleCategoryChange(category.id)}
                >
                  {category.title}
                </button>
              ))}
            </>
          )}
        </div>

        {/* Projects Grid */}
        <div className="workgrid">
          {loadingProjects ? (
            <p>Loading projects...</p>
          ) : projects.length > 0 ? (
            projects.map((project) => (
              <Link to={`/Residential1/${project.id}`} key={project.id}>
                <div className="workelement-item">
                  <div className="workitem-image">
                    {/* Check if project has images */}
                    <img
                      src={
                        project.project_images && project.project_images[0]
                          ? project.project_images[0].image
                          : "path/to/default/image.jpg"
                      }
                      alt={project.title}
                    />
                  </div>
                  <h1>{project.title}</h1>
                  <h2>
                    {project.category
                      ? project.category.title
                      : "Uncategorized"}
                  </h2>
                  <h3 className="workitem-title">{project.description}</h3>
                </div>
              </Link>
            ))
          ) : (
            <p>No projects available.</p>
          )}
        </div>
      </div>

      <Footer1 />
    </div>
  );
};

export default Works;
