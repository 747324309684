import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import DOMPurify from "dompurify"; // Import DOMPurify for sanitizing HTML
import Footer1 from "../navbar/Footer1";

export const Residential1 = () => {
  const { id } = useParams(); // Get the project ID from the URL
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState(null); // Manage error state

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(
          `https://api.vogelkoparchitects.com/api/api/project-detail/${id}/`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch project data");
        }

        const data = await response.json();
        setProject(data); // Set the project data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [id]);

  if (loading) return <div>Loading...</div>; // Show loading state
  if (error) return <div>Error: {error}</div>; // Show error message
  if (!project) return <div>No project data available.</div>; // Fallback if project data is null or undefined

  // Function to sanitize HTML content
  const createSafeHTML = (htmlContent) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  return (
    <div>
      {/* Page Title Section */}
      <div
        className="page-title dark-background"
        style={{
          backgroundImage: `url(${
            project.project_images?.[0]?.image || "/default-image.jpg"
          })`, // Default image fallback
        }}
      >
        <div className="container position-relative">
          <h1>{project.title}</h1>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Residential</li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Project Information Section */}
      <div className="bentomain">
        <div className="bento-main">
          <div className="bento-text">
            <h3>OUR PROJECT</h3>
            <p>
              <span>RESIDENCE</span> - "{project.title}"
            </p>
            <p>
              <span>CLIENT</span> - {project.client || "N/A"}
            </p>
            <p>
              <span>PROJECT YEAR</span> - {project.project_year || "N/A"}
            </p>
            <p>
              <span>LOCATION</span> - {project.location || "N/A"}
            </p>
          </div>
          <div className="bento-text">
            <p>
              <span>Site/Context</span>
            </p>
            <p
              dangerouslySetInnerHTML={createSafeHTML(
                project.site_context || "No site_context available."
              )}
            ></p>
            <br />
            <span>Program</span>
            <p
              dangerouslySetInnerHTML={createSafeHTML(
                project.program || "No program details available."
              )}
            ></p>
            <br />
            <span>Architectural response and climate</span>
            <p
              dangerouslySetInnerHTML={createSafeHTML(
                project.details || "No architectural response available."
              )}
            ></p>
            <br />
            <span>Materials/details/thermal strategies</span>
            <p
              dangerouslySetInnerHTML={createSafeHTML(
                project.materials || "No material details available."
              )}
            ></p>
          </div>
        </div>

        {/* Project Images Section */}
        <div className="bento">
          {project.project_images && project.project_images.length > 0 ? (
            project.project_images.map((image, index) => (
              <div key={index} className={`card${index + 1}`}>
                <img src={image.image} alt={`Card ${index + 1}`} />
              </div>
            ))
          ) : (
            <div>No images available for this project.</div>
          )}
        </div>
      </div>

      <Footer1 />
    </div>
  );
};

export default Residential1;
