import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import Header from "./components/navbar/Header";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Works from "./components/pages/Works";
import Projects from "./components/pages/Projects";
import Team from "./components/about/Team";
import Press from "./components/about/Press";
import Philosophy from "./components/about/Philosophy";

import Residential1 from "./components/works/Residantial1";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/works" element={<Works />}></Route>

          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/team" element={<Team />}></Route>
          <Route path="/press" element={<Press />}></Route>
          <Route path="/philosophy" element={<Philosophy />}></Route>

          <Route path="/Residential1/:id" element={<Residential1 />} />

          {/* <Route path='/Blog' element={<Blog/>}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
